import { useEffect, useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import SourceItem from "./SourceItem";
import { useParchaApi } from "@/hooks/useParchaApi";
import RiskCardV3 from "./RiskCardV3";
import { TopicChip } from "./AdverseMediaV2";
import { Hit } from "@/types";

const AdverseMediaV3 = ({
  apiKey,
  verifiedAdverseMediaHits,
  input,
  agentInstanceId,
  jobId,
  endpointUrl,
  agentKey,
  commandId,
  commandInstanceId,
  showAllContent = false,
  displayOnlyMatches = false,
  allCollapsed = false,
  displayType = false,
  showVendorId = false,
}: {
  apiKey: string;
  verifiedAdverseMediaHits: any;
  input: any;
  agentInstanceId: string;
  jobId: string;
  endpointUrl: string;
  agentKey: string;
  commandId: string;
  commandInstanceId: string;
  showAllContent: boolean;
  displayOnlyMatches: boolean;
  allCollapsed: boolean;
  displayType: boolean;
  showVendorId: boolean;
}) => {
  const parchaApi = useParchaApi();
  const [feedbackInputs, setFeedbackInputs] = useState<any[]>([]);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);

  useEffect(() => {
    const getFeedbackInput = async () => {
      let feedbackInputs;
      if (apiKey) {
        feedbackInputs = await parchaApi.getFeedbackInputsByKeyFromAPI(endpointUrl, jobId, "adverse_media", apiKey);
      } else {
        feedbackInputs = await parchaApi.getFeedbackInputsByKey(endpointUrl, jobId, "adverse_media");
      }

      if (feedbackInputs) {
        setFeedbackInputs(feedbackInputs);
      }
      setIsLoadingFeedback(false);
    };
    getFeedbackInput();
  }, []);

  const handleSubmitFeedback = async (isValid: boolean, rowId: string, comment: string) => {
    if (apiKey) {
      await parchaApi.sendFeedbackToAPI(
        endpointUrl,
        jobId,
        "adverse_media",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_adverse_media_hits: verifiedAdverseMediaHits,
              },
            },
          },
        },
        comment,
        rowId,
        apiKey,
      );
    } else {
      await parchaApi.sendFeedback(
        endpointUrl,
        jobId,
        "adverse_media",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_adverse_media_hits: verifiedAdverseMediaHits,
              },
            },
          },
        },
        comment,
        rowId,
      );
    }
    setFeedbackInputs([
      ...feedbackInputs.filter((feedback) => feedback.row_id !== rowId),
      {
        score: isValid,
        data: {
          world_check_id: rowId,
        },
        row_id: rowId,
        comment,
        created_at: new Date(),
      },
    ]);
  };

  const groupedByWorldCheckId = feedbackInputs.reduce((acc, feedback) => {
    const rowId = feedback.row_id;

    if (!acc[rowId] || new Date(acc[rowId].created_at) < new Date(feedback.created_at)) {
      acc[rowId] = feedback;
    }
    return acc;
  }, {});

  const filteredHits = verifiedAdverseMediaHits.filter((hit: Hit) =>
    displayOnlyMatches ? hit.escalate_for_review : true,
  );

  if (filteredHits.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {filteredHits.map((hit: Hit, index: number) => (
        <RiskCardV3
          type="adverse_media"
          key={`adverse-media-hit-${hit.id}-${index}`}
          hit={hit}
          input={input}
          handleSubmitFeedback={handleSubmitFeedback}
          feedback={groupedByWorldCheckId[hit.reference_id ?? hit.id ?? ""]}
          expandByDefault={index === 0 && !allCollapsed}
          isLoadingFeedback={isLoadingFeedback}
          renderContent={() => <EventInfo event={hit} showAllContent={showAllContent} />}
          showAllContent={showAllContent}
          displayType={displayType}
          showVendorId={showVendorId}
        />
      ))}
    </div>
  );
};

const EventInfo = ({ event, showAllContent }: { event: any; showAllContent: boolean }) => {
  const [articlesCount, setArticlesCount] = useState(
    showAllContent ? event.weblinks?.length : Math.min(3, event.weblinks?.length || 0),
  );

  useEffect(() => {
    if (showAllContent && event?.weblinks) {
      setArticlesCount(event.weblinks.length);
    } else if (event?.weblinks) {
      setArticlesCount(Math.min(3, event.weblinks.length));
    }
  }, [showAllContent, event?.weblinks]);

  return (
    <div className="w-0 min-w-full flex flex-col gap-y-4 text-xs">
      {(event?.title || event?.summary) && (
        <div className="flex flex-col gap-y-1">
          {event?.title && <span className="text-sm font-medium">{event.title}</span>}
          {event?.summary && <p className="text-slate-700">{event.summary}</p>}
        </div>
      )}

      {/** Here goes the list of Topics  */}
      {event.topics?.length ? (
        <div className="flex flex-wrap items-center gap-2 text-slate-900 font-medium">
          <span>Topics</span>
          {event.topics.map((topic: any, index: number) => (
            <TopicChip key={index} topic={topic} />
          ))}
        </div>
      ) : null}

      <div className="grid grid-cols-3 gap-4">
        {event?.reference_id && (
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">Vendor ID</span>
            <div className="flex flex-col gap-y-2 text-slate-700">{event.reference_id.split("_").at(-1)}</div>
          </div>
        )}
        {event?.id?.indexOf("e_tr_wci") > -1 && (
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">Vendor ID</span>
            <div className="flex flex-col gap-y-2 text-slate-700">{event.id.split("_").at(-1)}</div>
          </div>
        )}
        {event.when && (
          <div className="flex flex-col gap-2">
            <span className="font-medium">Year of Event</span>
            <span className="text-slate-700">{event.when}</span>
          </div>
        )}
        {event.associated_countries?.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="font-medium">Related Locations</span>
            <span className="text-slate-700">
              {event.associated_countries
                .map((country: any) =>
                  country !== null && typeof country === "object" && "country_name" in country
                    ? country.country_name
                    : country,
                )
                .join(", ")}
            </span>
          </div>
        )}
        {event.aliases?.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="font-medium">Aliases</span>
            <span className="text-slate-700">{event.aliases.join(", ")}</span>
          </div>
        )}
      </div>

      {event?.weblinks?.length > 0 && (
        <>
          <h4 className="text-sm font-semibold">Sources ({event.weblinks.length})</h4>
          <div className="flex flex-col gap-y-2">
            {event?.weblinks
              ?.sort(
                (a: any, b: any) =>
                  (b.metadata?.summary_of_relation_to_crime || b.metadata?.summary_of_event || b.summary || "").length -
                  (a.metadata?.summary_of_relation_to_crime || a.metadata?.summary_of_event || a.summary || "").length,
              )
              ?.slice(0, articlesCount)
              ?.map((source: any, index: number) => (
                <SourceItem
                  key={`adverse-media-source-item-${source.id}-${index}`}
                  url={source.url}
                  title={source.scanned_website?.webpage_title || source.metadata?.adverse_media_article_title}
                  sourceName={source.scanned_website?.source_name}
                  summary={
                    source.metadata?.summary_of_relation_to_crime || source.metadata?.summary_of_event || source.summary
                  }
                  text={source.scanned_website?.webpage_text}
                  hasPhoto={source.has_images_of_perpetrator}
                  wasRemoved={false}
                  who={source.metadata?.full_name}
                  when={source.metadata?.year_of_the_crime}
                  where={source.metadata?.associated_countries}
                  perpetratorAge={source.metadata?.age}
                />
              ))}
          </div>
          {!showAllContent && (
            <div className="flex flex-col gap-2 mb-4">
              {articlesCount && articlesCount > Math.min(3, event.weblinks.length) && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setArticlesCount(Math.min(3, event.weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Hide Sources
                    <ArrowUpwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
              {articlesCount && articlesCount < event.weblinks.length && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setArticlesCount(Math.min(articlesCount + 5, event.weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Show More Sources
                    <ArrowDownwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
            </div>
          )}
        </>
      )}

      {event?.sources?.length > 0 && (
        <div className="flex flex-col gap-y-2 mb-4">
          <h4 className="text-sm font-semibold">Sources ({event.sources.length})</h4>
          <div className="flex flex-col gap-y-2">
            {event.sources.map((source: any, index: number) => (
              <SourceItem
                key={`adverse-media-source-item-${source.id}-${index}`}
                url={source.url}
                title={source.title}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdverseMediaV3;
